@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  .subline {
    @apply h-[2px] rounded w-3/4 bg-slate-400 opacity-0 m-auto transition-all duration-300;
  }
  .midBoxContainer {
    @apply flex justify-center bg-slate-300 hover:bg-slate-700 hover:text-slate-300 duration-200 rounded-md p-2;
  }
  .midBoxInside {
    @apply flex flex-col xl:flex-row items-center justify-center space-x-0 lg:space-x-2;
  }
  .paginationButtons {
    @apply flex items-center gap-2;
  }
  .paginationButtons a {
    @apply p-3 rounded-sm bg-white hover:bg-slate-300 duration-300;
  }
  .paginationActivated a {
    @apply bg-slate-400 duration-300 text-white;
  }
}

@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap');

.paginationDisabled a {
  display: none;
}
